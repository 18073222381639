import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
        <div className=''>
                Contact me at:
        <a className='' href='mailto:adam@ry3.io'>
                adam@ry3.io
            </a>
        </div>
        
    </div>
  )
}

export default Footer
